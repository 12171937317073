<template>
  <div id="hospitalHome" class="w-full pt-10">
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/5"></div>

      <vx-card
        class="vx-col md:w-1/3 text-center cursor-pointer backgroundShadow"
        :style="propBG1"
      >
        <div class="vx-row">
          <div class="w-full mt-3 mt-20 mb-10">
            <h3 style="color: #454a62" class="text-center">
              {{ $t("Specialties") }}
            </h3>
          </div>
        </div>

        <div class="vx-row ml-3" style="margin-top: 5%; margin-bottom: 12%">
          <div class="w-full">
            <vs-button
              class="mr-2 customizer-btn rounded-lg text-sm"
              color="primary"
              style="width: 95% !important"
              @click="getSpecialties()"
            >
              <img src="@/assets/images/doctor/arrowIcon.svg" alt="" />
            </vs-button>
          </div>
        </div>
      </vx-card>

      <vx-card
        class="vx-col md:w-1/3 text-center cursor-pointer backgroundShadow"
        :style="propBG2"
      >
        <div class="vx-row">
          <div class="w-full mt-3 mt-20 mb-10">
            <h3 style="color: #454a62" class="text-center">
              {{ $t("Doctors") }}
            </h3>
          </div>
        </div>

        <div class="vx-row ml-3" style="margin-top: 5%; margin-bottom: 12%">
          <div class="w-full">
            <vs-button
              class="mr-2 customizer-btn rounded-lg text-sm"
              color="primary"
              style="width: 95% !important"
              @click="getDoctors()"
            >
              <img src="@/assets/images/doctor/arrowIcon.svg" alt="" />
            </vs-button>
          </div>
        </div>
      </vx-card>
    </div>
    <!-- <div   class=" hidden
                sm:hidden
                md:hidden
                lg:block " >
      <h2 style="font-weight: bold; position: absolute; left: 10%; top: 65%">
        {{ $t("ShortAccess") }}
      </h2>
    </div> -->
    <div class="mt-12 pb-5">
      <div class="vx-row">
        <div class="vx-col md:w-1/4"></div>
        <vs-button
          class="vx-col lg:w-1/4 m-3 w-full customizer-btn rounded-lg text-sm"
          color="primary"
          @click="AddSpecialties()"
          >{{ $t("AddSpecialties") }}
        </vs-button>
 <vs-button
          class="vx-col lg:w-1/4 m-3 w-full customizer-btn rounded-lg text-sm"
          color="primary"
          @click="AddDoctors()"
          >{{ $t("AddDoctors") }}
        </vs-button>
      
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/4"></div>
         <vs-button
          class="vx-col lg:w-1/4 m-3 w-full customizer-btn rounded-lg text-sm"
          color="primary"
          @click="SurgeriesTreatments()"
          >{{ $t("SurgeriesTreatments") }}
        </vs-button>

        <vs-button
          class="vx-col lg:w-1/4 m-3 w-full customizer-btn rounded-lg text-sm"
          color="primary"
          @click="ManagePayments()"
          >{{ $t("PaymentStatus") }}
        </vs-button>
      </div>
    </div>

    <vs-popup fullscreen title="" :active.sync="showReservedPayments">
        <payment  
        v-if="showReservedPayments"
         @closePop="changePaymentStatus" />
    </vs-popup>

      <vs-popup  title="" :active.sync="ShowChangeStatus">
          <SurgerySetPopup
            :itemModel="addItemModel"
              v-if="ShowChangeStatus"
              @closePop="ShowChangeStatus = false"
              @SaveChange="SaveChange()" >
          </SurgerySetPopup>
      </vs-popup>
  </div>
</template>

<script>
import HospitalModule from "@/store/hospital/moduleHospital.js";
import payment from "@/views/hospital/payments.vue";
import SurgerySetPopup from "./SurgerySetPopup.vue"
import modulePaymentStatus from "@/store/settings/paymentStatus/modulePaymentStatus.js";

export default {
  components: {payment,SurgerySetPopup},
  data() {
    return {
      ShowChangeStatus:false,
addItemModel:{},
      showReservedPayments:false,
      propBG1: {
        backgroundImage: `url(${require("@/assets/images/hospital/card1.png")}) `,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },
      propBG2: {
        backgroundImage: `url(${require("@/assets/images/hospital/card2.png")})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },
      arrowIcon: {
        backgroundImage: `url(${require("@/assets/images/hospital/arrowIcon.svg")})`,
        backgroundRepeat: "no-repeat",
      },
    };
  },

  methods: {
    getSpecialties() {
       this.$router.push("/hospital/Specialty");
    },
    getDoctors() {
       this.$router.push("/hospital/Doctor");
    },
    AddSpecialties() {
      this.$router.push({ name: "AddHospitalSpecialites" });
    },
    SurgeriesTreatments() {
      this.$router.push({ name:"ReservedSurgery" });
    },
    AddDoctors() {
      this.$router.push({ name: "AddHospitalDoctors" });
    },
    changePaymentStatus(model){
      debugger
    this.showReservedPayments = false
    if(model.IsShow==true){
     this.addItemModel = model;
    this.ShowChangeStatus = true;
    }
   
    },
    ManagePayments() {
       this.showReservedPayments=true;
    },
      SaveChange()
    {
   
         if(this.addItemModel.viewType==3)
        {
             this.$store.dispatch("HospitalList/UpdatePatientReservationSurgery",this.addItemModel).then((res)=>{
                this.$vs.notify({
                  title: this.$t("success"),
                  text: res.data.Message,
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "success",
                });
                this.ShowChangeStatus=false;
                this.searchReservedSurgery();
             }).catch((err)=>{
             this.$vs.notify({
              title: this.$t("Error"),
              text: err.data.Message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
            this.ShowChangeStatus=false;
             });
          }
        
    },
  },
  created() {
    if (!HospitalModule.isRegistered) {
      // this.$vs.loading();
      this.$store.registerModule("HospitalList", HospitalModule);
      HospitalModule.isRegistered = true;
    }
      if (!modulePaymentStatus.isRegistered) {
      this.$store.registerModule(
        "PaymentStatusList",
        modulePaymentStatus
      );
      modulePaymentStatus.isRegistered = true;
    }

    this.$store.dispatch("PaymentStatusList/GetAllPaymentStatus");
    
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style scoped>
#hospitalHome .backgroundShadow {
  background-color: transparent;
  box-shadow: rgb(0 0 0 / 20%) 5px 5px 15px;
  box-shadow: rgb(0 0 0 / 0%) 5px 5px 15px;
}

#hospitalHome .vs-button {
  margin-bottom: 20px !important;
}
#hospitalHome .text-sm {
  font-size: 0.95rem !important;
  font-weight: 600;
}
#hospitalHome h3 {
  font-weight: bold;
  font-size: 2rem !important;
}
</style>
