<template>
      <div id="payments" class=" items-center justify-center inset-0 ..." style="">
    
      <table
         style="background: white;margin: auto!important"
         class="text-center shadow-md xl:w-2/3 w-full m-3"
        >
          <tr class="HeaderTableColor">
            <th class="  h-32 text-center">{{$t("Surgery")}}</th>
            <th class="  h-32 text-center">{{$t("Patient")}}</th>
            <th class="  h-32 text-center">
              {{$t("Date")}}
            </th>
            <th class="  h-32 text-center">
                 <div class="MyPopClose">
                <feather-icon   icon="XIcon" svgClasses="w-7 h-7 hover:text-primary stroke-current" @click.stop="$emit('closePop',true)" />
            </div>
              {{$t("Status")}}
            </th>
          </tr>
          <tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in reservedSurgeries"
            class="text-xl h-24 p-1 m-1 text-center">
            <td>
              <span>{{ tr.HospitalSurgery.Surgery.Name }} </span>
            </td>
            <td>
                <span >{{ tr.Patient.Name }}</span>
            </td>
            <td>
                <span >{{ tr.SurgeryDateFrom.split("T")[0] }}</span>
            </td>
             <td>
                <span v-if="tr.PaymentTypeID!=5" :style="[tr.IsPaymentDue?{'color':'red'}:{}]">{{ PaymentStatus(tr) }}</span>
                <u v-else style="cursor: pointer" :style="[tr.IsPaymentDue?{'color':'red'}:{}]" @click="ShowChangeStatus=true;addItemModel={viewType:3,
               ID:tr.ID,StatusID:tr.StatusID,SurgeryDateFrom:tr.SurgeryDateFrom,SurgeryDateTo:tr.SurgeryDateTo,
               PaymentStatusID:tr.PaymentStatusID,CurrentStatusID:tr.PaymentStatusID};changePaymentStatus();">
               {{ PaymentStatus(tr) }}</u>
          
            </td>
          </tr>

          <tr class="h-10  text-center">
            <td></td>
          </tr>
        </table>
         
  </div>
</template>
<script>
import modulePatient from "@/store/Patient/modulePatient.js";

export default {
    data(){
        return{
ShowChangeStatus:false,
addItemModel:{},
        }
    }, 
     components:{
    
  },
    computed: {
        reservedSurgeries() {
          return this.$store.state.patientList.PatientReservationSurgeries;
        },
    },
    methods:{
        PaymentStatus(tr)
    {
      debugger
       if(tr.PaymentStatus.ID==7)
            return  !this.$vs.rtl? "Pending":"قيد الانتظار";
       else
         return tr.PaymentStatus.Name;
    },
   changePaymentStatus(){
     debugger
     this.addItemModel.IsShow = true;
     this.$emit("closePop",this.addItemModel);
   }
    },
    created(){
         if (!modulePatient.isRegistered) {
                this.$store.registerModule("patientList", modulePatient);
                modulePatient.isRegistered = true;
         }
         this.$store.dispatch("patientList/getPatientReservationSurgery",{
                HospitalID: this.$store.state.AppActiveUser.Hospital.ID,
                NotIncludePaymentStatusIds : [1]
            });
    }
}
</script>
<style>
#payments table {
  border-collapse: collapse;
  border-radius: 1.5em;
  overflow: hidden;
  background: white;
}
#payments th,
#payments td {
  border: 1px solid #85828220;
  border-collapse: collapse;
  text-align: center;
  font: normal normal normal 20px/28px Futura PT;
  height: 50px;
}

#payments  .mycard .vx-card {
  /* padding: 0px !important; */
  background: unset;
}
#payments  .login-tabs-container {
  min-height: 505px;
}
#payments  .con-tab {
  padding-bottom: 14px;
}

#payments  .con-slot-tabs {
  margin-top: 1rem;
}
.background {
  color: rgb(255, 9, 9);
}
#payments  .stack-top {
  z-index: 9;
  border-radius: 5px;
  background: #202020;
}
#payments  .head {
  color: white;
  text-align: center;
  margin-top: 20px;
}

</style>